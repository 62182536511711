export const USER_SCREEN_VALIDATE_SUCCESS = 'USER_SCREEN_VALIDATE_SUCCESS';
export const USER_SCREEN_RESET_RECOVER = 'USER_SCREEN_RESET_RECOVER';

export const MINIMAL_DATA_FORM = 'MINIMAL_DATA_FORM';
export const MINIMAL_DATA_VALIDATE_EMAIL = 'MINIMAL_DATA_VALIDATE_EMAIL';
export const MINIMAL_DATA_RESET_EMAIL = 'MINIMAL_DATA_RESET_EMAIL';

export const SEND_EMAIL_CODE_REQUEST = 'SEND_EMAIL_CODE_REQUEST';
export const SEND_EMAIL_CODE_REQUEST_FAIL = 'SEND_EMAIL_CODE_REQUEST_FAIL';
export const SEND_EMAIL_CODE_REQUEST_SUCCESS =
    'SEND_EMAIL_CODE_REQUEST_SUCCESS';

export const VERIFY_EMAIL_CODE_REQUEST = 'VERIFY_EMAIL_CODE_REQUEST';
export const VERIFY_EMAIL_CODE_REQUEST_FAIL = 'VERIFY_EMAIL_CODE_REQUEST_FAIL';
export const VERIFY_EMAIL_CODE_REQUEST_SUCCESS =
    'VERIFY_EMAIL_CODE_REQUEST_SUCCESS';

export const CODE_SCREEN_MODE_UPDATE = 'CODE_SCREEN_MODE_UPDATE';

export const RESET_FULL_CODE_SCREEN = 'RESET_FULL_CODE_SCREEN';

export const LOG_USER_INFORMATION_REQUEST = 'LOG_USER_INFORMATION_REQUEST';
export const LOG_USER_INFORMATION_SUCCESS = 'LOG_USER_INFORMATION_SUCCESS';
export const LOG_USER_INFORMATION_FAIL = 'LOG_USER_INFORMATION_FAIL';

export const CODE_SCREEN_RESET = 'CODE_SCREEN_RESET';
export const CODE_SCREEN_VALIDATE = 'CODE_SCREEN_VALIDATE';
export const CODE_SCREEN_VALIDATE_SUCCESS = 'CODE_SCREEN_VALIDATE_SUCCESS';
export const CODE_SCREEN_VALIDATE_FAILURE = 'CODE_SCREEN_VALIDATE_FAILURE';

export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE';

export const FINAL_CODE_SCREEN_VALIDATE = 'FINAL_CODE_SCREEN_VALIDATE';
export const FINAL_CODE_SCREEN_VALIDATE_SUCCESS =
    'FINAL_CODE_SCREEN_VALIDATE_SUCCESS';
export const FINAL_CODE_SCREEN_VALIDATE_FAILURE =
    'FINAL_CODE_SCREEN_VALIDATE_FAILURE';
export const UPDATE_FINAL_SUMMARY_SCREEN_STATE = 'UPDATE_FINAL_SUMMARY_SCREEN_STATE';
export const SURVEY_SCREEN_LOAD = 'SURVEY_SCREEN_LOAD';
export const SURVEY_SCREEN_VALIDATE = 'SURVEY_SCREEN_VALIDATE';
export const SURVEY_SCREEN_VALIDATE_SUCCESS = 'SURVEY_SCREEN_VALIDATE_SUCCESS';
export const SURVEY_SCREEN_VALIDATE_FAILURE = 'SURVEY_SCREEN_VALIDATE_FAILURE';

export const PACKAGE_SCREEN_VALIDATE = 'PACKAGE_SCREEN_VALIDATE';
export const PACKAGE_SCREEN_VALIDATE_SUCCESS =
    'PACKAGE_SCREEN_VALIDATE_SUCCESS';
export const PACKAGE_SCREEN_VALIDATE_FAILURE =
    'PACKAGE_SCREEN_VALIDATE_FAILURE';

export const FETCH_CALENDAR_APPOINTMENT = 'FETCH_CALENDAR_APPOINTMENT';
export const FETCH_CALENDAR_APPOINTMENT_SUCCESS =
    'FETCH_CALENDAR_APPOINTMENT_SUCCESS';
export const FETCH_CALENDAR_APPOINTMENT_FAILURE =
    'FETCH_CALENDAR_APPOINTMENT_FAILURE';

export const DATA_SCREEN_VALIDATE_SUCCESS = 'DATA_SCREEN_VALIDATE_SUCCESS';
export const DATA_SCREEN_VALIDATE_SUCCESS_FINAL =
    'DATA_SCREEN_VALIDATE_SUCCESS_FINAL';
export const DATA_SCREEN_SAVE_COMMON = 'DATA_SCREEN_SAVE_COMMON';
export const DATA_SCREEN_SAVE_BIRTHTOWN = 'DATA_SCREEN_SAVE_BIRTHTOWN';

export const SUMMARY_SCREEN_VALIDATE_SUCCESS =
    'SUMMARY_SCREEN_VALIDATE_SUCCESS';
export const SUMMARY_SCREEN_CHANGE_OFFER = 'SUMMARY_SCREEN_CHANGE_OFFER';
export const SUMMARY_SCREEN_CHANGE_OPTION = 'SUMMARY_SCREEN_CHANGE_OPTION';
export const SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS =
    'SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS';
export const SUMMARY_SCREEN_CHANGE_OPTION_FAILURE =
    'SUMMARY_SCREEN_CHANGE_OPTION_FAILURE';
export const SUMMARY_SCREEN_GET_SPONSOR = 'SUMMARY_SCREEN_GET_SPONSOR';
export const SUMMARY_SCREEN_GET_PARTNER = 'SUMMARY_SCREEN_GET_PARTNER';

export const FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES =
    'FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES';
export const FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES =
    'FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES';
export const FINAL_SUMMARY_SCREEN_VALIDATE = 'FINAL_SUMMARY_SCREEN_VALIDATE';
export const FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS =
    'FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS';
export const FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE =
    'FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE';
export const FINAL_SUMMARY_SCREEN_SET_REFERENCE =
    'FINAL_SUMMARY_SCREEN_SET_REFERENCE';

export const FINAL_PAYMENT_SCREEN_VALIDATE = 'FINAL_PAYMENT_SCREEN_VALIDATE';
export const FINAL_PAYMENT_SCREEN_VALIDATE_SUCCESS =
    'FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS';
export const FINAL_PAYMENT_SCREEN_VALIDATE_FAILURE =
    'FINAL_PAYMENT_SCREEN_VALIDATE_FAILURE';
export const FINAL_PAYMENT_SCREEN_SET_REFERENCE = 'FINAL_PAYMENT_SCREEN_SET_REFERENCE';
export const FINAL_PAYMENT_SCREEN_CHANGE_ADDITIONAL_RATES = 'FINAL_PAYMENT_SCREEN_CHANGE_ADDITIONAL_RATES';
export const FINAL_PAYMENT_SCREEN_CHANGE_PACKAGES = 'FINAL_PAYMENT_SCREEN_CHANGE_PACKAGES';

export const CLOSE_SAVE_DRAFT_MODAL = 'CLOSE_SAVE_DRAFT_MODAL';

export const PAYMENT_MODAL_LOAD = 'PAYMENT_MODAL_LOAD';
export const PAYMENT_MODAL_VALIDATE = 'PAYMENT_MODAL_VALIDATE';
export const PAYMENT_MODAL_VALIDATE_SUCCESS = 'PAYMENT_MODAL_VALIDATE_SUCCESS';
export const PAYMENT_MODAL_VALIDATE_FAILURE = 'PAYMENT_MODAL_VALIDATE_FAILURE';

export const PREVIOUS_SCREEN_LOAD = 'PREVIOUS_SCREEN_LOAD';
export const NEXT_SCREEN_LOAD = 'NEXT_SCREEN_LOAD';
export const MAIN_SCREEN_SAVE_ORDER = 'MAIN_SCREEN_SAVE_ORDER';
export const MAIN_SCREEN_SAVE_ORDER_SUCCESS = 'MAIN_SCREEN_SAVE_ORDER_SUCCESS';
export const MAIN_SCREEN_SAVE_ORDER_FAILURE = 'MAIN_SCREEN_SAVE_ORDER_FAILURE';
export const MAIN_SCREEN_GET_ORDER = 'MAIN_SCREEN_GET_ORDER';
export const MAIN_SCREEN_GET_ORDER_SUCCESS = 'MAIN_SCREEN_GET_ORDER_SUCCESS';
export const MAIN_SCREEN_GET_ORDER_PACKAGE_SUCCESS =
    'MAIN_SCREEN_GET_ORDER_PACKAGE_SUCCESS';
export const MAIN_SCREEN_GET_ORDER_PRODUCT_SUCCESS =
    'MAIN_SCREEN_GET_ORDER_PRODUCT_SUCCESS';
export const MAIN_SCREEN_GET_ORDER_FAILURE = 'MAIN_SCREEN_GET_ORDER_FAILURE';
export const MAIN_SCREEN_GET_PACKAGE_FAILURE =
    'MAIN_SCREEN_GET_PACKAGE_FAILURE';
export const MAIN_SCREEN_GET_OFFER_FAILURE = 'MAIN_SCREEN_GET_OFFER_FAILURE';
export const MAIN_SCREEN_GET_PRODUCT_FAILURE =
    'MAIN_SCREEN_GET_PRODUCT_FAILURE';
export const MAIN_SCREEN_GET_FORM = 'MAIN_SCREEN_GET_FORM';

export const RECALL_PAYMENT_CB = 'RECALL_PAYMENT_CB';
export const FIRST_PAYMENT_CB_FAILURE = 'FIRST_PAYMENT_CB_FAILURE';
export const FAILURE_CB_MESSAGE = 'FAILURE_CB_MESSAGE';

export const VALIDATE_DIRECT_DEBIT_PAYMENT = 'VALIDATE_DIRECT_DEBIT_PAYMENT';

export const REDIRECT_FINAL_SCREEN = 'REDIRECT_FINAL_SCREEN';
export const CHECK_CONTRACT_PAYMENT_CB = 'CHECK_CONTRACT_PAYMENT_CB';

export const REDIRECT_TO_DATA_SCREEN_START_DATE =
    'REDIRECT_TO_DATA_SCREEN_START_DATE';

export const FINAL_CODE_SCREEN_VALIDATE_V2 = 'FINAL_CODE_SCREEN_VALIDATE_V2';

export const GET_LOGGED_USER_INFO_REQUEST = 'GET_LOGGED_USER_INFO_REQUEST';
export const GET_LOGGED_USER_INFO_REQUEST_FAIL =
    'GET_LOGGED_USER_INFO_REQUEST_FAIL';
export const GET_LOGGED_USER_INFO_REQUEST_SUCCESS =
    'GET_LOGGED_USER_INFO_REQUEST_SUCCESS';
