import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CustomInput } from '../../components';
import CustomSelectV2 from '../../components/CustomSelect/CustomSelectV2';
import CustomToggleV2 from '../../components/CustomToggle/CustomToggleV2';
import { camelToUpperCase } from '../../utils/helpers';

const SwitchContainer = ({
                             handleShowInput,
                             handleChangeInput,
                             showInput,
                             toggleTitle,
                             showBorder,
                             thirdParty: { code, message, error, loading },
                             select,
                             contracts,
                         }) => {
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');

    useEffect(() => {
        setInputError('');
        setInputValue('');
    }, [select.currentValue.value]);

    const channel = localStorage.getItem('channel');

    const validateInput = (value) => {
        const safeContracts = contracts || [];

        const isNG = safeContracts.some(contract => contract.energy === "NG");
        if (isNG && select.currentValue.value === 'PROMOTION' && value === 'ELECTRICLIC') {
            setInputError('Le code promo n\'est pas disponible pour l\'offre que tu as choisis');
            return false;
        }
        if ((!channel || (channel.toUpperCase() === "WEB" && channel.toUpperCase() === "WEB-ADS"))&& select.currentValue.value === 'PROMOTION' && value !== 'ELECTRICLIC') {
            setInputError('Code promo non disponible');
            return false;
        }

        if (select.currentValue.value === 'SPONSOR') {
            if (!value.startsWith('CUF') || value.length !== 11) {
                setInputError('Le code parrain doit commencer par "CUF" et faire 11 caractères');
                return false;
            }
        }

        if (!value) {
            setInputError('');
            return true;
        }

        setInputError('');
        return true;
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (validateInput(value)) {
            handleChangeInput(e, code);
        } else {
            handleChangeInput(e, null);
        }
    };

    return (
        <div className="switch-container">
            <div
                className={`switch-container__toggle ${
                    showBorder ? 'switch-container__toggle--border' : ''
                }`}
                onClick={handleShowInput}
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
            >
                <CustomToggleV2
                    checked={showInput}
                    text={toggleTitle}
                    labelSize="1.1"
                    handleCheck={() => {}}
                />
            </div>
            {showInput && (
                <div className="switch-container__inputs">
                    <CustomSelectV2
                        id="advantageCode"
                        value={select.currentValue}
                        options={select.options}
                        defaultValue={select.defaultValue}
                        handleChange={select.handleChange}
                        placeholder="Sélectionner"
                        customSize="210px"
                    />

                    <CustomInput
                        text=""
                        value={
                            select.currentValue.value === 'SPONSOR'
                                ? camelToUpperCase(inputValue)
                                : inputValue
                        }
                        pattern={
                            select.currentValue.value === 'SPONSOR'
                                ? '[a-zA-Z0-9]*'
                                : undefined
                        }
                        loading={loading}
                        placeholder={select.currentValue.placeholder}
                        onInputChange={handleInputChange}
                        isLocked={!select.currentValue.value}
                    />
                </div>
            )}
            {message && (
                <p
                    className={`switch-container__message switch-container__message--${
                        error ? 'red' : 'green'
                    }`}
                >
                    {message}
                </p>
            )}
            {inputError && (
                <p
                    className={`switch-container__message switch-container__message--red`}
                >
                    {inputError}
                </p>
            )}
            {select.currentValue.description && (
                <p className="switch-container__description">
                    {select.currentValue.description}
                </p>
            )}
        </div>
    );
};

SwitchContainer.propTypes = {
    handleShowInput: PropTypes.func.isRequired,
    handleChangeInput: PropTypes.func.isRequired,
    toggleTitle: PropTypes.string.isRequired,
    showBorder: PropTypes.bool,
    select: PropTypes.shape({
        currentValue: PropTypes.object,
        defaultValue: PropTypes.object,
        options: PropTypes.array,
        handleChange: PropTypes.func,
    }).isRequired,
};

SwitchContainer.defaultProps = {
    showBorder: false,
};

export default SwitchContainer;
