import React from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Typography/Title';
import WordingConstant from '../../utils/wording.json';
import Screen from '../screen';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Typography from '../../components/Typography/Typography';
import { FontFamilyVariant, BillingMode } from '../../constants/enums';
import './_ValidationDialogScreen.scss';
import * as actions from '../FinalSummaryScreen/FinalSummaryScreen.actions';
import { CustomButton, CustomCheckbox } from '../../components';
import MenLongArm from '../../assets/icons/ic-mister-long-arm.png';
import DownloadIcon from '../../assets/icons/ic-download.png';
import { scrollToRefDelay } from '../../utils/scroll';
import { CheckBoxVariant } from '../../components/CustomCheckbox/CustomCheckbox';
import {
    isScheduleWithoutPackBillingMode,
    multiplicationInstallmentFrequency,
} from '../../utils/helpers';
import * as paymentActions from '../../containers/PaymentModal/PaymentModal.actions';
import * as FinalpaymentActions from '../FinalPaymentScreen/FinalPaymentScreen.action';
import LoadingOverlay from '../../components/LoadingOverlay';
import PaymentModal from '../../containers/PaymentModal/PaymentModal';

const Wording = WordingConstant.FinalSummaryScreen;
const linkPriceAndCGV = WordingConstant.Wekiwi.articles[4].link;

export class ValidationDialogScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.FINALPAYMENT,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.DATA,
        step: 9,
        setStepById: null,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
        saveData: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            // mode: CLIENT_MODE.NEW,
            isConfirmed: false,
            isCommunicationConfirmed: false,
            showValidationModal: false,
            acceptTerms: false,
            acceptEmail: false,
            acceptSepa: false,
            iframeOpened: false,
            typePayment: false,
        };
        this.myRef = React.createRef();
        this.onUpdate = this.onUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.energyByType = this.energyByType.bind(this);
        this.searchFrequency = this.searchFrequency.bind(this);
    }

    searchFrequency = (val, type) =>
        Wording.frequency[type].find(el => el.value === val).text || '';

    onUpdate() {
        const { dataState } = this.props;
        const { showValidationModal } = this.state;
        if (dataState.emailValid.isValid && showValidationModal)
            this.setState({ showValidationModal: false });
    }

    componentDidMount() {
        ValidationDialogScreen.navigationOptions.validate = this.nextScreenValidation;
        const { isCurrentScreen } = this.props;
        const { showValidationModal } = this.state;
        if (!showValidationModal && isCurrentScreen)
            scrollToRefDelay(this.myRef, 200);
    }

    nextScreenValidation = () => true;

    energyByType = energy => {
        switch (energy) {
            case 'EL':
                return 'électricité';
            case 'NG':
                return 'gaz naturel';
            default:
                return '';
        }
    };

    renderOptionInformation = (energy, summaryState) => (
        <ul>
            {summaryState[energy].additionalOptions.map((item, itemIndex) => {
                const labelOption = item;
                const option = summaryState[
                    energy
                ].offer.product.additionalRates.filter(
                    items => items.label === labelOption
                )[0];
                if (!option) {
                    return null;
                }
                return (
                    <li key={itemIndex}>
                        <span>{`L'option ${Wording.option_package_title[
                            item
                        ].toLowerCase()}`}</span>{' '}
                        {`qui ajoute + ${option.detail.price}${
                            option.detail.unit === '€/MONTH' ? '€/mois' : '€'
                        } ${Wording.option_package[item]}`}
                    </li>
                );
            })}
        </ul>
    );

    renderPackageInformation() {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        return (
            <ul style={{ marginRight: '40px' }}>
                {energyTypes.map((item, itemIndex) => {
                    if (summaryState[item].cyclicBill === true) {
                        return (
                            <li key={itemIndex}>
                                <span>{`Un offre ${this.energyByType(
                                    item
                                )} avec facturation cyclique `}</span>
                                {Wording.cyclicOffer}
                                {this.renderOptionInformation(
                                    item,
                                    summaryState
                                )}
                            </li>
                        );
                    }
                    if (summaryState[item].chosenPackages.length > 1) {
                        const winterData = summaryState[
                            item
                        ].chosenPackages.filter(
                            season => season.timeframe === 'WINTER'
                        )[0];
                        const summerData = summaryState[
                            item
                        ].chosenPackages.filter(
                            season => season.timeframe === 'SUMMER'
                        )[0];

                        return (
                            <li key={itemIndex}>
                                <span>{`Un forfait ${this.energyByType(
                                    item
                                )} été/hiver`}</span>
                                {` de ${
                                    summerData.packageQuantity
                                } kWh (été) à ${summerData.amount}${
                                    summerData.currency
                                }/mois et 
                                    ${
                                        winterData.packageQuantity
                                    } kWh (hiver) à ${winterData.amount}${
                                    winterData.currency
                                }/mois avec une frequence de prélèvement 
                                    ${this.searchFrequency(
                                        summaryState[item].installmentFrequency,
                                        'values'
                                    ).toLowerCase()}.`}
                                {this.renderOptionInformation(
                                    item,
                                    summaryState
                                )}
                            </li>
                        );
                    }
                    return (
                        <li key={itemIndex}>
                            <span>{`Un forfait ${this.energyByType(
                                item
                            )} `}</span>
                            {`de ${
                                summaryState[item].chosenPackages[0]
                                    .packageQuantity
                            } kWh à ${
                                summaryState[item].chosenPackages[0].amount
                            }${
                                summaryState[item].chosenPackages[0].currency
                            }/mois avec une frequence de prélèvement
                                    ${this.searchFrequency(
                                        summaryState[item].installmentFrequency,
                                        'values'
                                    ).toLowerCase()}.`}
                            {this.renderOptionInformation(item, summaryState)}
                        </li>
                    );
                })}
            </ul>
        );
    }

    componentDidUpdate(prevState) {
        const isCyclic = autorizedBillingModes =>
            Boolean(
                autorizedBillingModes &&
                    autorizedBillingModes.find(
                        mode =>
                            mode.billingModeCode ===
                            BillingMode.CYCLICAL_BILLING
                    )
            );
        const {
            paymentState,
            summaryState,
            paymentReducer: { iframe, stripe, situation },
            setIsModalOpen,
        } = this.props;
        const { isConfirmed, isCommunicationConfirmed } = this.state;
        const paymentActivated =
            (isConfirmed && isCommunicationConfirmed) ||
            iframe !== null ||
            stripe !== null ||
            situation !== 'MES';

        if (paymentActivated) setIsModalOpen(true);
        else setIsModalOpen(false);
        this.onUpdate();
        const { setCanGoNext } = this.props;
        if (this.nextScreenValidation()) setCanGoNext(true);
        else setCanGoNext(false);
        if (
            paymentState &&
            ((paymentState.typePayment === 'SDD' &&
                paymentState.isDirectDebitSuccess &&
                (paymentState.status === 'FINALIZED' ||
                    paymentState.status === 'FINALIZED_WITHOUT_DOC')) ||
                (paymentState.isDirectDebitSuccess &&
                    (paymentState.firstPaymentCBStatus === 'PENDING' ||
                        paymentState.firstPaymentSlimCollectStatus ===
                            'PENDING') &&
                    (paymentState.status === 'FINALIZED' ||
                        paymentState.status === 'FINALIZED_WITHOUT_DOC')))
        ) {
            const { setStepById } = this.props;
            if (
                paymentState.typePayment === 'SDD' ||
                (summaryState &&
                    ((summaryState.EL.cyclicBill === true && isCyclic) ||
                        (summaryState.NG.cyclicBill === true && isCyclic)))
            ) {
                setStepById('FINAL_SCREEN');
            } else {
                setStepById('STEP_PAYMENT');
            }
            return Promise.resolve();
        }
    }

    callOrderStatus = () => {
        const {
            paymentState: { currentOrder, externalReference, reference },
            validatePaymentScreenDispatch,
        } = this.props;

        const prepareOrder = {
            ...currentOrder,
            externalReference,
            orderReference: reference,
        };

        validatePaymentScreenDispatch({
            reference,
            prepareOrder,
            // logger: {
            //     tag: TagLogger.FINALSUMMARY,
            //     triggerButton: TriggerButtonLogger.VALIDATE_BTN,
            // },
        });
    };

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    prepareDataToConfirmationModal = () => {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        const { LATER } = this.state;
        const reduce = (acc, current) => {
            const {
                installmentFrequency,
                chosenPackages,
                cyclicBill,
            } = summaryState[current];

            if (!cyclicBill && LATER === 'LATER') {
                if (chosenPackages.length > 1) {
                    return [
                        ...acc,
                        {
                            energy: current,
                            type: 'SUMMER_WINTER',
                        },
                    ];
                }
                const sumAmountByPackage = chosenPackages.reduce(
                    (a, c) => parseFloat(c.amount) + a,
                    0
                );
                const sumAmountByPackageFrequency = multiplicationInstallmentFrequency(
                    installmentFrequency,
                    sumAmountByPackage
                );

                return [
                    ...acc,
                    {
                        energy: current,
                        sumAmountByPackage,
                        sumAmountByPackageFrequency,
                        installmentFrequency,
                        type: 'BASE',
                    },
                ];
            }

            return [
                ...acc,
                {
                    energy: current,
                    type: 'CYCLIC_BILL',
                },
            ];
        };

        return energyTypes.reduce(reduce, []);
    };

    isPaymentCB = () => {
        const {
            summaryState: { contracts },
        } = this.props;

        return contracts.reduce(
            (acc, { firstPaymentCB }) =>
                acc || (firstPaymentCB !== undefined ? firstPaymentCB : false),
            false
        );
    };

    handleValidate = () => {
        const isCyclic = autorizedBillingModes =>
            Boolean(
                autorizedBillingModes &&
                    autorizedBillingModes.find(
                        mode =>
                            mode.billingModeCode ===
                            BillingMode.CYCLICAL_BILLING
                    )
            );
        const {
            userState,
            dataState,
            summaryState,
            paymentState,
            validateFinalPaymentScreenDispatch,
        } = this.props;

        if (
            dataState &&
            dataState.common &&
            dataState.common.birthdate === 'Invalid Date'
        ) {
            dataState.common.birthdate = '';
        }
        this.setState({ showPaymentModal: true });
        if (
            paymentState &&
            (paymentState.firstPaymentCBStatus === 'PENDING' ||
                paymentState.firstPaymentSlimCollectStatus === 'PENDING') &&
            (paymentState.status === 'FINALIZED' ||
                paymentState.status === 'FINALIZED_WITHOUT_DOC')
        ) {
            const { setStepById } = this.props;

            if (
                paymentState.typePayment === 'SDD' ||
                (summaryState &&
                    ((summaryState.EL.cyclicBill === true && isCyclic) ||
                        (summaryState.NG.cyclicBill === true && isCyclic)))
            ) {
                setStepById('FINAL_SCREEN');
            } else {
                setStepById('STEP_PAYMENT');
            }
            return Promise.resolve();
        }

        const typeOrder =
            dataState &&
            dataState.common.dateContract.switch &&
            dataState.common.dateContract.switch.value === 'LATER'
                ? 'SDD'
                : 'SLMCLT';

        return validateFinalPaymentScreenDispatch({
            ...userState,
            ...dataState,
            ...summaryState,
            ...paymentState,
            retryCB: typeOrder !== 'SDD',
            typeOrder,
            alreadyClient:
                dataState.logingInformation.validated === true ||
                dataState.logingInformation.validated === 'true',
            nextScreen: true,
        });
    };

    getPriceTotalByMonth = frequency => {
        const foundNumberOfMonth = Wording.frequency.days.find(
            day => day.value === frequency
        );
        return foundNumberOfMonth.text;
    };

    render() {
        const { acceptTerms, acceptEmail, acceptSepa } = this.state;
        const {
            packageState: { autorizedBillingModes },
            mainState: { loading, error },
            summaryState,
        } = this.props;
        const isScheduleWithoutPack = isScheduleWithoutPackBillingMode(
            autorizedBillingModes
        );
        const data = this.prepareDataToConfirmationModal();
        const allIsCyclicBill = data.reduce(
            (acc, current) => current.type === 'CYCLIC_BILL' && acc,
            true
        );
        const renderIfNotCyclic = (elm, elmCyclic) => {
            if (summaryState && summaryState.EL && summaryState.NG) {
                return summaryState.EL.cyclicBill === true ||
                    summaryState.NG.cyclicBill === true
                    ? elmCyclic
                    : elm;
            }
            return elm;
        };
        const paymentCB = this.isPaymentCB();
        const allSum = data.reduce(
            (acc, { sumAmountByPackageFrequency }) =>
                sumAmountByPackageFrequency
                    ? acc + sumAmountByPackageFrequency
                    : acc,
            0
        );
        const isDual = data.find(({ type }) => type === 'SUMMER_WINTER');

        const {
            paymentReducer: { iframe, stripe, situation },
        } = this.props;
        const { isConfirmed, isCommunicationConfirmed } = this.state;
        const paymentActivated =
            (isConfirmed && isCommunicationConfirmed) ||
            iframe !== null ||
            stripe !== null ||
            situation !== 'MES';

        const isCyclic = autorizedBillingModes =>
            Boolean(
                autorizedBillingModes &&
                    autorizedBillingModes.find(
                        mode =>
                            mode.billingModeCode ===
                            BillingMode.CYCLICAL_BILLING
                    )
            );

        return (
            <>
                <LoadingOverlay loading={loading} fullscreen />
                <div ref={this.myRef} />
                <SectionLayout
                    step={
                        isCyclic
                            ? ValidationDialogScreen.navigationOptions.step - 1
                            : ValidationDialogScreen.navigationOptions.step
                    }
                >
                    <Title
                        titleFormatGowun="Tout est OK,"
                        titleFormatLexand="prêt à signer ?"
                    />
                    <Typography className="subtitle">
                        {Wording.validation.recall}
                    </Typography>
                    {renderIfNotCyclic(
                        <React.Fragment>
                            <p className="subtitle-bold">
                                {Wording.validation.frequency.base}
                            </p>
                        </React.Fragment>,
                        <React.Fragment>
                            <p className="subtitle-bold">
                                {Wording.validation.frequency.cyclic}
                            </p>
                        </React.Fragment>
                    )}
                    <div className="package-summary">
                        <Typography
                            className="subtitle"
                            fontWeight={FontFamilyVariant}
                        >
                            En bref :
                        </Typography>
                        {this.renderPackageInformation()}
                        <img
                            alt="Men show the package summray text with long arm"
                            src={MenLongArm}
                        />
                    </div>

                    {!isScheduleWithoutPack &&
                        renderIfNotCyclic(
                            <p className="subtitle-bold">
                                <span>
                                    {
                                        Wording.validation[
                                            paymentCB
                                                ? 'attentionCB'
                                                : 'attention'
                                        ]
                                    }
                                    {allSum !== 0 &&
                                        !isDual &&
                                        !allIsCyclicBill &&
                                        data.map((item, itemIndex) => {
                                            const text = ` de ${
                                                item.sumAmountByPackageFrequency
                                            }€ en  ${this.energyByType(
                                                item.energy
                                            )} (${item.sumAmountByPackage}€ x ${
                                                allIsCyclicBill === false
                                                    ? this.getPriceTotalByMonth(
                                                          item.installmentFrequency
                                                      )
                                                    : ''
                                            })`;
                                            return itemIndex === data.length - 1
                                                ? text
                                                : `${text} et`;
                                        })}
                                </span>
                            </p>,
                            <br />
                        )}
                    <div className="checkbox-container">
                        <CustomCheckbox
                            variant={CheckBoxVariant.V2}
                            id="2"
                            defaultValue={acceptTerms}
                            onChange={v => this.handleChange('acceptTerms', v)}
                        >
                            <Typography>
                                {Wording.validation.checkboxLabel}
                            </Typography>
                            <div className="link-download">
                                <img
                                    alt="download icons"
                                    src={DownloadIcon}
                                    className="download-icon"
                                />
                                <a
                                    href={linkPriceAndCGV}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {Wording.validation.link}
                                </a>
                            </div>
                        </CustomCheckbox>
                        <CustomCheckbox
                            id="3"
                            variant={CheckBoxVariant.V2}
                            defaultValue={acceptSepa}
                            onChange={v => this.handleChange('acceptSepa', v)}
                        >
                            <Typography>
                                {Wording.validation.checkboxLabel_mondatSepa}
                            </Typography>
                        </CustomCheckbox>
                        <CustomCheckbox
                            id="4"
                            variant={CheckBoxVariant.V2}
                            defaultValue={acceptEmail}
                            onChange={v => this.handleChange('acceptEmail', v)}
                        >
                            <Typography>
                                {
                                    Wording.validation
                                        .checkboxLabel_communications
                                }
                            </Typography>
                        </CustomCheckbox>
                    </div>
                    <div className="button-container validation-button-container">
                        <CustomButton
                            disabledBackgroundColor="#f3f3f3"
                            onClick={() => this.handleValidate()}
                            disabled={!acceptTerms || !acceptSepa}
                            title={Wording.validation.btn_validate}
                            color="orange"
                            loading={loading}
                        />
                        <p className="custom-input__div__error">{error}</p>
                    </div>
                    {this.state.showPaymentModal && paymentActivated ? (
                        <PaymentModal setStepById={() => {}} />
                    ) : null}
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dataState: state.dataReducer,
    paymentReducer: state.paymentReducer,
    summaryState: state.summaryReducer,
    userState: state.userReducer,
    packageState: state.packageReducer,
    mainState: state.mainReducer,
    paymentState: state.paymentReducer,
});

const mapDispatchToProps = dispatch => ({
    validateFinalSummaryScreenDispatch: payload =>
        dispatch(actions.validateFinalSummaryScreen(payload)),
    validatePaymentScreenDispatch: payload =>
        dispatch(paymentActions.validatePaymentScreen(payload)),
    validateFinalPaymentScreenDispatch: payload =>
        dispatch(FinalpaymentActions.validateFinalPaymentScreen(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidationDialogScreen);
